<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <!-- <b>{{ formTitle }}</b> -->
      <b>{{ $t('服务管理.用户授权管理.审核详情')}} </b>

    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="用户id,外键{zb_user.id}" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id,外键{zb_user.id}" />
      </a-form-model-item>-->
      <a-form-model-item :label="$t('服务管理.用户授权管理.项目地址')" prop="address">
        <a-input v-model="form.address" :placeholder="$t('服务管理.用户授权管理.请输入项目地址')" disabled />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.用户授权管理.业主姓名')" prop="name" >
        <a-input v-model="form.name" :placeholder="$t('服务管理.用户授权管理.请输入业主姓名')" disabled />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.用户授权管理.土地面积')" prop="landArea" >
        <a-input v-model="form.landArea" :placeholder="$t('服务管理.用户授权管理.请输入土地面积')" disabled />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.用户授权管理.开发经验')" prop="developmentExperience" >
        <a-select v-model="form.developmentExperience" disabled="readonly">
          <a-select-option :value="0">无</a-select-option>
          <a-select-option :value="1">有</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.用户授权管理.身份')" prop="identity" v-if="form.identity" >
        <img  v-viewer style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;"
              :src="form.identity" preview="身份"/>
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.用户授权管理.服务内容')" prop="serviceContentList" >
        <a-input v-model="form.serviceContentList" :placeholder="$t('服务管理.用户授权管理.请输入内容')" disabled="readonly"  type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.用户授权管理.签名')" prop="signature" v-if="form.signature" >
        <img  v-viewer style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;"
              :src="form.signature" preview="身份"/>
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.用户授权管理.审核状态')" prop="status" >
        <a-radio-group v-model="form.status">
          <a-radio :value="1">{{$t('服务管理.用户授权管理.通过')}}</a-radio>
          <a-radio :value="2">{{$t('服务管理.用户授权管理.驳回')}}</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.用户授权管理.驳回原因')" prop="reason" v-if="form.status === 2" >
        <a-input v-model="form.reason" :placeholder="$t('服务管理.用户授权管理.请输入驳回原因')" />
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getEmpower, addEmpower, updateEmpower } from '@/api/user/empower'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        address: null,

        name: null,

        price: null,

        developmentExperience: null,

        identity: null,

        serviceContentIds: null,

        serviceContentList: null,

        signature: null,

        status: 0,

        reason: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id,外键{zb_user.id}不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '项目地址不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '业主姓名不能为空', trigger: 'blur' }
        ],
        landArea: [
          { required: true, message: '土地面积不能为空', trigger: 'blur' }
        ],
        developmentExperience: [
          { required: true, message: '开发经验,0-无,1-有不能为空', trigger: 'blur' }
        ],
        identity: [
          { required: true, message: '身份不能为空', trigger: 'blur' }
        ],
        serviceContentIds: [
          { required: true, message: '服务内容id,外键{zb_service_content.id},多个使用逗号隔开不能为空', trigger: 'blur' }
        ],
        signature: [
          { required: true, message: '签名不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '审核状态,0-待审核,1-已审核,2-已驳回不能为空', trigger: 'blur' }
        ],
        reason: [
          { required: true, message: '驳回原因不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        address: null,
        name: null,
        price: null,
        developmentExperience: null,
        identity: null,
        serviceContentIds: null,
        serviceContentList: null,
        signature: null,
        status: 0,
        reason: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getEmpower({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateEmpower(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addEmpower(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
