import request from '@/utils/request'


// 查询用户授权列表
export function listEmpower(query) {
  return request({
    url: '/user/userEmpower/list',
    method: 'get',
    params: query
  })
}

// 查询用户授权分页
export function pageEmpower(query) {
  return request({
    url: '/user/userEmpower/page',
    method: 'get',
    params: query
  })
}

// 查询用户授权详细
export function getEmpower(data) {
  return request({
    url: '/user/userEmpower/detail',
    method: 'get',
    params: data
  })
}

// 新增用户授权
export function addEmpower(data) {
  return request({
    url: '/user/userEmpower/add',
    method: 'post',
    data: data
  })
}

// 修改用户授权
export function updateEmpower(data) {
  return request({
    url: '/user/userEmpower/edit',
    method: 'post',
    data: data
  })
}

// 删除用户授权
export function delEmpower(data) {
  return request({
    url: '/user/userEmpower/delete',
    method: 'post',
    data: data
  })
}
